<template>
    <AGHeadlessButton ref="$root" :class="renderedClasses" :disabled="disabled">
        <slot />
    </AGHeadlessButton>
</template>

<script setup lang="ts">
import { Colors, booleanProp, elementRef, enumProp, removeInteractiveClasses, stringProp } from '@aerogel/core';
import { computed } from 'vue';
import { twMerge } from 'tailwind-merge';
import type { HasElement } from '@aerogel/core';

const props = defineProps({
    class: stringProp(''),
    color: enumProp(Colors, Colors.Primary),
    disabled: booleanProp(),
});

const $root = elementRef();
const baseClasses =
    'clickable-target flex items-center justify-center rounded-lg px-3 py-2 whitespace-nowrap focus-visible:outline';
const colorClasses = computed(() => {
    switch (props.color) {
        case Colors.Secondary:
            return 'ring-1 ring-inset ring-gray-300 hover:bg-gray-50';
        case Colors.Clear:
            return 'hover:bg-gray-100 focus-visible:outline-gray-700';
        case Colors.Danger:
            return [
                'bg-red-600 text-white shadow-sm',
                'hover:bg-red-500',
                'focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600',
            ].join(' ');
        case Colors.Primary:
        default:
            return [
                'bg-[--primary-600] text-white shadow-sm',
                'hover:bg-[--primary-500]',
                'focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[--primary-600]',
            ].join(' ');
    }
});
const variantClasses = computed(() => {
    const classes = `${colorClasses.value} ${baseClasses}`;

    if (!props.disabled) {
        return classes;
    }

    return `${removeInteractiveClasses(classes)} opacity-50`;
});
const renderedClasses = computed(() => twMerge(variantClasses.value, props.class));

defineExpose<HasElement>({ $el: $root });
</script>
